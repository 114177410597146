import React from 'react';
import { Container, Typography } from '@mui/material';

function AboutPage() {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        About Cohost
      </Typography>
      <Typography variant="body1" gutterBottom>
        Cohost is an innovative event management platform designed to simplify the process of organizing, attending, and securing events. Our mission is to connect organizers, attendees, and security personnel seamlessly.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whether you're hosting a small gathering or a large conference, Cohost provides the tools you need to manage guest lists, coordinate security, and ensure a successful event.
      </Typography>
    </Container>
  );
}

export default AboutPage;
