import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NavBar from './components/NavBar';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AttendeeDashboard from './pages/AttendeeDashboard';
import OrganizerDashboard from './pages/OrganizerDashboard';
import SecurityDashboard from './pages/SecurityDashboard';
import CreateEventPage from './pages/CreateEventPage';
import CreateNewEventPage from './pages/CreateNewEventPage';
import GuestListManager from './pages/GuestListManager';
import UploadGuestListPage from './pages/UploadGuestListPage';
import AddGuestPage from './pages/AddGuestPage';
import ProtectedRoute from './components/ProtectedRoute';
import ManageEventPage from './pages/ManageEventPage';
import EventDetailsPage from './pages/EventDetailsPage';
import CheckInPage from './pages/CheckInPage';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        
        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute element={<RoleBasedDashboard />} />} />
        <Route path="/create-event" element={<ProtectedRoute element={<CreateEventPage />} />} />
        <Route path="/create-new-event" element={<ProtectedRoute element={<CreateNewEventPage />} />} />
        <Route path="/guest-list-manager" element={<ProtectedRoute element={<GuestListManager />} />} />
        <Route path="/events/:id/upload-guestlist" element={<ProtectedRoute element={<UploadGuestListPage />} />} />
        <Route path="/events/:id/add-guest" element={<ProtectedRoute element={<AddGuestPage />} />} />
        <Route path="/events/:id/manage" element={<ProtectedRoute element={<ManageEventPage />} />} />
        <Route path="/events/:id" element={<ProtectedRoute element={<EventDetailsPage />} />} />
        <Route path="/events/:id/check-in" element={<ProtectedRoute element={<CheckInPage />} />} />
        {/* ... other protected routes ... */}
      </Routes>
    </Router>
  );
}

function RoleBasedDashboard() {
  const currentRole = localStorage.getItem('currentRole');

  if (currentRole === 'attendee') {
    return <AttendeeDashboard />;
  } else if (currentRole === 'organizer') {
    return <OrganizerDashboard />;
  } else if (currentRole === 'security') {
    return <SecurityDashboard />;
  } else {
    return <Typography variant="h6">Unknown role</Typography>;
  }
}

export default App;
