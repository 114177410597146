import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';

function HomePage() {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Box
        sx={{
          backgroundImage: 'url(/images/hero-bg.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          py: { xs: 8, md: 15 },
        }}
      >
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h2" gutterBottom>
            Welcome to Cohost
          </Typography>
          <Typography variant="h5" gutterBottom>
            Simplify your event management experience.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/register"
            sx={{ mt: 4 }}
          >
            Get Started
          </Button>
        </Container>
      </Box>

      <Container sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Easy Event Creation</Typography>
            <Typography>
              Organize events effortlessly with our intuitive tools.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Seamless Guest Management</Typography>
            <Typography>
              Manage your guest lists with CSV uploads or manual entries.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Secure Check-Ins</Typography>
            <Typography>
              Ensure smooth and secure event check-ins with our platform.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default HomePage;
