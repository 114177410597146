import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Button,
  Alert,
  Box,
  LinearProgress,
} from '@mui/material';

function UploadGuestListPage() {
  const { id: eventId } = useParams();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const authToken = localStorage.getItem('authToken');

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a CSV file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('guestlist', file);

    setUploading(true);
    try {
      const response = await axios.post(
        `/api/events/${eventId}/upload-guestlist`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setMessage(response.data.message);
      setFile(null);
    } catch (error) {
      setMessage(
        `Error: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Upload Guest List
      </Typography>
      {message && (
        <Alert
          severity={message.startsWith('Error') ? 'error' : 'success'}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleUpload}>
        <Button variant="contained" component="label">
          Choose File
          <input
            type="file"
            accept=".csv"
            hidden
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Button>
        {file && (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {file.name}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={uploading}
        >
          Upload
        </Button>
        {uploading && <LinearProgress sx={{ mt: 2 }} />}
      </Box>
    </Container>
  );
}

export default UploadGuestListPage;
