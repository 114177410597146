import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
} from '@mui/material';

function CreateNewEventPage() {
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    location: '',
    date: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const authToken = localStorage.getItem('authToken');

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        '/api/events',
        formValues,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setMessage('Event created successfully!');
      navigate(`/events/${response.data.event._id}/manage`);
    } catch (error) {
      setMessage(
        `Error: ${error.response?.data?.message || error.message}`
      );
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create a New Event
      </Typography>
      {message && (
        <Alert
          severity={message.includes('Error') ? 'error' : 'success'}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleCreateEvent}>
        <TextField
          label="Event Name"
          value={formValues.name}
          onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={formValues.description}
          onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Location"
          value={formValues.location}
          onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          type="date"
          label="Date"
          value={formValues.date}
          onChange={(e) => setFormValues({ ...formValues, date: e.target.value })}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Create Event
        </Button>
      </Box>
    </Container>
  );
}

export default CreateNewEventPage;
