import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AttendeeDashboard() {
  const [events, setEvents] = useState([]);
  const [message, setMessage] = useState('');

  const authToken = localStorage.getItem('authToken');
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    fetchMyEvents();
    // eslint-disable-next-line
  }, []);

  const fetchMyEvents = async () => {
    try {
      const response = await axios.get('/api/events', {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      // Filter events where the user is a guest
      const myEvents = response.data.filter((event) =>
        event.guests.some((guest) => guest.email === userEmail)
      );

      setEvents(myEvents);
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Events
      </Typography>
      {message && <Alert severity="error">{message}</Alert>}
      {events.length === 0 ? (
        <Typography variant="body1">You have no upcoming events.</Typography>
      ) : (
        <Grid container spacing={4}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event._id}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{event.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {event.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to={`/events/${event._id}`}>
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default AttendeeDashboard;
