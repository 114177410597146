import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4F46E5', // A modern purple color
    },
    secondary: {
      main: '#F43F5E', // A modern pink/red color
    },
    background: {
      default: '#F9FAFB', // Light gray background
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
