import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Alert,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function EventDetailsPage() {
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [message, setMessage] = useState('');

  const authToken = localStorage.getItem('authToken');
  const currentRole = localStorage.getItem('currentRole');

  useEffect(() => {
    fetchEvent();
    // eslint-disable-next-line
  }, []);

  const fetchEvent = async () => {
    try {
      const response = await axios.get(`/api/events/${eventId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setEvent(response.data);
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleRSVP = async () => {
    try {
      const response = await axios.post(
        `/api/events/${eventId}/rsvp`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  if (!event) {
    return (
      <Container sx={{ py: 4 }}>
        {message ? <Alert severity="error">{message}</Alert> : <Typography>Loading...</Typography>}
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        {event.name}
      </Typography>
      {message && <Alert severity={message.startsWith('Error') ? 'error' : 'success'}>{message}</Alert>}
      <Typography variant="body1" gutterBottom>
        {event.description}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Location: {event.location}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Date: {new Date(event.date).toLocaleDateString()}
      </Typography>
      {currentRole === 'attendee' && (
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleRSVP}>
            RSVP to Event
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default EventDetailsPage;
