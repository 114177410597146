import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

function NavBar() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const roles = JSON.parse(localStorage.getItem('roles') || '["attendee"]');
  const currentRole = localStorage.getItem('currentRole') || roles[0];
  const userName = localStorage.getItem('userName') || 'User';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [roleAnchorEl, setRoleAnchorEl] = React.useState(null);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRoleMenuOpen = (event) => {
    setRoleAnchorEl(event.currentTarget);
  };

  const handleRoleMenuClose = () => {
    setRoleAnchorEl(null);
  };

  const handleRoleSwitch = (newRole) => {
    localStorage.setItem('currentRole', newRole);
    handleRoleMenuClose();
    navigate('/dashboard');
  };

  const renderUserMenu = (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
      <MenuItem disabled>{userName}</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderRoleMenu = (
    <Menu
      anchorEl={roleAnchorEl}
      open={Boolean(roleAnchorEl)}
      onClose={handleRoleMenuClose}
    >
      {roles.map((roleOption) => (
        <MenuItem
          key={roleOption}
          selected={roleOption === currentRole}
          onClick={() => handleRoleSwitch(roleOption)}
        >
          {roleOption.charAt(0).toUpperCase() + roleOption.slice(1)}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: 'none', color: 'primary.main' }}
        >
          Cohost
        </Typography>
        {authToken ? (
          <>
            <Button color="primary" component={Link} to="/dashboard">
              Dashboard
            </Button>
            <IconButton color="primary" onClick={handleRoleMenuOpen}>
              <SwapHorizIcon />
            </IconButton>
            <IconButton onClick={handleMenuOpen} color="primary">
              <Avatar sx={{ bgcolor: 'primary.main' }}>
                {userName.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          </>
        ) : (
          <>
            <Button color="primary" component={Link} to="/login">
              Login
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/register"
              sx={{ ml: 2 }}
            >
              Sign Up
            </Button>
          </>
        )}
      </Toolbar>
      {renderUserMenu}
      {renderRoleMenu}
    </AppBar>
  );
}

export default NavBar;
