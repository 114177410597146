import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Alert,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

function ManageEventPage() {
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [message, setMessage] = useState('');

  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    fetchEvent();
    // eslint-disable-next-line
  }, []);

  const fetchEvent = async () => {
    try {
      const response = await axios.get(`/api/events/${eventId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setEvent(response.data);
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  if (!event) {
    return (
      <Container sx={{ py: 4 }}>
        {message ? <Alert severity="error">{message}</Alert> : <Typography>Loading...</Typography>}
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Event: {event.name}
      </Typography>
      {message && <Alert severity="error">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/events/${eventId}/upload-guestlist`}
          sx={{ mr: 2 }}
        >
          Upload Guest List
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/events/${eventId}/add-guest`}
          sx={{ mr: 2 }}
        >
          Add Guest Manually
        </Button>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={`/events/${eventId}/guests`}
        >
          View Guests
        </Button>
      </Box>
    </Container>
  );
}

export default ManageEventPage;
