import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function CheckInPage() {
  const { id: eventId } = useParams();
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');

  const authToken = localStorage.getItem('authToken');

  const handleCheckIn = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/events/${eventId}/check-in`,
        { code },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setMessage(response.data.message);
      setCode('');
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Check-In Guests
      </Typography>
      {message && (
        <Alert
          severity={message.startsWith('Error') ? 'error' : 'success'}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleCheckIn}>
        <TextField
          label="Guest Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Check In
        </Button>
      </Box>
    </Container>
  );
}

export default CheckInPage;
