import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
} from '@mui/material';

function AddGuestPage() {
  const { id: eventId } = useParams();
  const [formValues, setFormValues] = useState({ name: '', email: '', phone: '' });
  const [message, setMessage] = useState('');

  const authToken = localStorage.getItem('authToken');

  const handleAddGuest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/events/${eventId}/add-guest`,
        formValues,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setMessage(response.data.message);
      setFormValues({ name: '', email: '', phone: '' });
    } catch (error) {
      setMessage(
        `Error: ${error.response?.data?.message || error.message}`
      );
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add Guest
      </Typography>
      {message && (
        <Alert
          severity={message.startsWith('Error') ? 'error' : 'success'}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleAddGuest}>
        <TextField
          label="Name"
          value={formValues.name}
          onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={formValues.email}
          onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          value={formValues.phone}
          onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Add Guest
        </Button>
      </Box>
    </Container>
  );
}

export default AddGuestPage;
