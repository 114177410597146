import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function CreateEventPage() {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create Event
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/create-new-event"
          fullWidth
          sx={{ mb: 2 }}
        >
          Create a Brand New Event
        </Button>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/guest-list-manager"
          fullWidth
        >
          Use Guest List Manager
        </Button>
      </Box>
    </Container>
  );
}

export default CreateEventPage;
