import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Alert } from '@mui/material';

function ContactPage() {
  const [formValues, setFormValues] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send email)
    setSubmitted(true);
    setFormValues({ name: '', email: '', message: '' });
  };

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      {submitted && <Alert severity="success">Your message has been sent!</Alert>}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          required
          value={formValues.name}
          onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
        />
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          required
          value={formValues.email}
          onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
        />
        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          required
          value={formValues.message}
          onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Send Message
        </Button>
      </Box>
    </Container>
  );
}

export default ContactPage;
